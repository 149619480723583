import {ChartConfiguration, ChartDataSets, ChartOptions, ChartType} from 'chart.js';

export class ChartBuilder {
    private chart: ChartConfiguration = {
        type: null,
        data: {
            labels: [],
            datasets: []
        },
        options: {
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero:true
                    }
                }]
            }
        }
    };

    private constructor(private chartType: ChartType) {
        this.chart.type = chartType;
    }

    setOptions(options: ChartOptions): ChartBuilder {
        this.chart.options = options;
        return this;
    }

    appendOptions(options: ChartOptions): ChartBuilder {
        this.chart.options = Object.assign(this.chart.options, options);
        return this;
    }

    setLabels(labels: string[]): ChartBuilder {
        this.chart.data.labels = labels;
        return this;
    }

    appendLabel(label: string): ChartBuilder {
        this.chart.data.labels.push(label);
        return this;
    }

    appendDataSet(dataset: ChartDataSets): ChartBuilder {
        this.chart.data.datasets.push(dataset);
        return this;
    }

    appendDataSets(datasets: ChartDataSets[]): ChartBuilder {
        this.chart.data.datasets.push(...datasets);
        return this;
    }

    build(): ChartConfiguration {
        return this.chart;
    }

    static create(chartType: ChartType): ChartBuilder {
        return new ChartBuilder(chartType);
    }
}
